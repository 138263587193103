import { useState } from "react";
import { WSearchField } from "../components/forms/WSearchField.tsx";
import { WTable, WTableBody } from "../components/table/WTable.tsx";
import { AdminTitle } from "./AdminTitle.tsx";

export function AccountsView() {
    const [queryString, setQueryString] = useState("");

    return (
        <>
            <AdminTitle title="Billing Accounts" />

            <WTable adminTable search={<WSearchField value={queryString} onChange={setQueryString} />}>
                <thead>
                    <tr>
                        <th />
                        <th>User ID</th>
                        <th>Created At</th>
                        <th>Deleted At</th>
                        <th>Billing Account</th>
                        <th>BA ID</th>
                        <th>Title</th>
                        <th>Customer Name</th>
                        <th>Customer Phone</th>
                        <th>Customer ID Number</th>
                        <th>Company Name</th>
                        <th>Invoice Email</th>
                        <th>Usage Charges</th>
                        <th>Credit</th>
                        <th>Ongoing Balance</th>
                        <th>Days In Debt</th>
                        <th>Payment Plan</th>
                        <th>Payment Method</th>
                        <th>Automated Top Up</th>
                        <th>Invoice Email Sending</th>
                        <th>Discount</th>
                        <th>VAT</th>
                        <th>Restriction Level</th>
                        <th>Status</th>
                        <th>Campaign Codes Used</th>
                        <th>Referral Share Code</th>
                        <th>Suspend Reason</th>
                        <th>Deleted</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    <tr></tr>
                </WTableBody>
            </WTable>
        </>
    );
}
